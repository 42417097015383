import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import MTBreadcrumb from "@app-components/header/MTBreadcrumb";
import PolicyContact from "@app-components/policy/PolicyContact";
import {
  generateLink,
  getEmailLink,
} from "@app-components/policy/PolicyContent";
import PrivacyContent, {
  privacyContent,
} from "@app-components/policy/PrivacyContent";
import { getRoutes } from "@app-utils/routes";

export default function Privacy() {
  const { t } = useTranslation();
  const routes = getRoutes(t);

  const internalLink = (href: string, title?: string, className?: string) => {
    return generateLink({
      type: "internal",
      href,
      alias: title,
      classNames: className || "underline lowercase",
    });
  };

  const summaryQuestions = useMemo(() => {
    return [
      {
        question: t("page.privacy.summary.question1.question"),
        answer: t("page.privacy.summary.question1.answer", {
          link: internalLink(
            privacyContent.personalinfo,
            t("page.privacy.links.personalInfoYouDisclose")
          ),
        }),
      },
      {
        question: t("page.privacy.summary.question2.question"),
        answer: t("page.privacy.summary.question2.answer", {
          link: internalLink(
            privacyContent.sensitiveinfo,
            t("page.privacy.links.sensitiveInfoProcess")
          ),
        }),
      },
      {
        question: t("page.privacy.summary.question3.question"),
        answer: t("page.privacy.summary.question3.answer", {
          link: internalLink(
            privacyContent.othersources,
            t("page.privacy.infocollect.otherSources.title")
          ),
        }),
      },
      {
        question: t("page.privacy.summary.question4.question"),
        answer: t("page.privacy.summary.question4.answer", {
          link: internalLink(
            privacyContent.infouse,
            t("page.privacy.summary.question4.link")
          ),
        }),
      },
      {
        question: t("page.privacy.summary.question5.question"),
        answer: t("page.privacy.summary.question5.answer", {
          link: internalLink(
            privacyContent.whoshare,
            t("page.privacy.summary.question5.link")
          ),
        }),
      },
      {
        question: t("page.privacy.summary.question6.question"),
        answer: t("page.privacy.summary.question6.answer", {
          link: internalLink(
            privacyContent.infosafe,
            t("page.privacy.summary.question6.link")
          ),
        }),
      },
      {
        question: t("page.privacy.summary.question7.question"),
        answer: t("page.privacy.summary.question7.answer", {
          link: internalLink(
            privacyContent.privacyrights,
            t("page.privacy.privacyrights.link")
          ),
        }),
      },
      {
        question: t("page.privacy.summary.question8.question"),
        answer: t("page.privacy.summary.question8.answer", {
          link: internalLink(
            privacyContent.personalinfo,
            t("page.privacy.infocollect.personal.title")
          ),
        }),
      },
      {
        question: t("page.privacy.summary.question8.question"),
        answer: t("page.privacy.summary.question8.answer", {
          link: generateLink({ type: "internal", path: "data-request" }),
        }),
      },
      {
        answer: t("page.privacy.summary.question9.question", {
          link: internalLink(
            "table-content",
            t("page.privacy.tableContent"),
            "underline"
          ),
        }),
        normalFont: true,
      },
    ];
  }, [t]);

  return (
    <div className="container policy faq-detail">
      <MTBreadcrumb items={[routes.home, routes.privacyPolicy]} />
      <div className="faq-detail-container">
        <div className="wrapper">
          <div className="faq-detail-header">
            <div className="faq-detail-header-container">
              <div className="faq-detail-title">{t("page.privacy.title")}</div>
              <div className="faq-detail-subtitle">
                {t("page.privacy.subtitle")}
              </div>
            </div>
          </div>
          <div className="policy-content">
            <div className="mt-text-body-lg">
              <p>
                {t("page.privacy.overview.content.1", {
                  companyName: t("contact.companyName"),
                })}
              </p>
              <p className="pt-4">{t("page.privacy.overview.content.2")}</p>
              <ul className="pt-4 list-disc list-inside">
                <li
                  className=""
                  dangerouslySetInnerHTML={{
                    __html: t("page.privacy.overview.li.1", {
                      link: generateLink({ type: "internal", path: "" }),
                    }),
                  }}
                />
                <li className="pt-2">{t("page.privacy.overview.li.2")}</li>
                <li className="pt-2">{t("page.privacy.overview.li.3")}</li>
              </ul>
              <p
                className="pt-4"
                dangerouslySetInnerHTML={{
                  __html: t("page.terms.overview.content.2", {
                    email: getEmailLink("destouches"),
                  }),
                }}
              />
              <div className="mt-title-xl pb-4 pt-4">
                {t("page.privacy.summary.title")}
              </div>
              <div>{t("page.privacy.summary.summary", { link: "" })}</div>
              {summaryQuestions.map((question: any, index: number) => (
                <div className="pt-4" key={index}>
                  <span>
                    {!question.normalFont ? (
                      <strong>{question.question} </strong>
                    ) : (
                      question.question
                    )}{" "}
                  </span>
                  <span
                    dangerouslySetInnerHTML={{ __html: question.answer }}
                  ></span>
                </div>
              ))}
            </div>

            <PrivacyContent />
          </div>
          <PolicyContact />
        </div>
      </div>
    </div>
  );
}
