export default function MTInput({
  className,
  label,
  value,
  error,
  disabled,
  onChangeText,
  onBlur,
  onFocus,
}: {
  className?: string;
  label: string;
  value?: string;
  error?: string;
  disabled?: boolean;
  onChangeText?: (text: string) => void;
  onBlur?: () => void;
  onFocus?: () => void;
}) {
  return (
    <div className={className}>
      <div className="input relative w-full z-10 mt-input">
        <input
          value={value}
          type="text"
          className="
            block
            py-[6px]
            w-full
            bg-transparent
            border-0
            border-b
            border-b-metalic-silver
            appearance-none
            focus:outline-none
            focus:ring-0
            peer
          "
          disabled={disabled}
          // dont remove this line
          placeholder=" "
          // dont remove this line
          autoComplete="off"
          onChange={(e) => onChangeText?.(e.target.value)}
          onBlur={onBlur}
          onFocus={() => onFocus?.()}
        />
        <label
          className="
            absolute
            duration-300
            transform
            -translate-y-[8px]
            flex
            items-center
            h-[34px]
            top-0
            left-0
            -z-10
            origin-[0]
            peer-placeholder-shown:translate-y-[16px]
            peer-placeholder-shown:text-[18px]
            peer-placeholder-shown:leading-6
            peer-focus:-translate-y-[8px]
            peer-focus:bottom-0
            text-tertiary
            select-none
            peer-focus:text-[14px]
            peer-focus:leading-[20px]
          "
        >
          {label}
        </label>
      </div>
      {!!error && (
        <div className="input-error text-red-600 text-md pt-2 text-left">
          {error}
        </div>
      )}
    </div>
  );
}
