import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import PolicyContent, {
  PolicyContentData,
  generateItem,
  generateLink,
  generateList,
  generateTable,
  getEmailLink,
} from "./PolicyContent";

export const privacyContent = {
  infocollect: "infocollect",
  infouse: "infouse",
  whoshare: "whoshare",
  sociallogins: "sociallogins",
  inforetain: "inforetain",
  infosafe: "infosafe",
  infominors: "infominors",
  privacyrights: "privacyrights",
  dnt: "dnt",
  caresidents: "caresidents",
  policyupdate: "policyupdate",
  contact: "contact",
  request: "request",
  personalinfo: "personalinfo",
  sensitiveinfo: "sensitiveinfo",
  othersources: "othersources",
};

export default function PrivacyContent() {
  const { t } = useTranslation();

  const tableContent: PolicyContentData = useMemo(() => {
    const content = {
      [privacyContent.infocollect]: {
        title: t("page.privacy.infocollect.title"),
        href: privacyContent.infocollect,
        items: [
          {
            title: t("page.privacy.infocollect.personal.title"),
            titleId: privacyContent.personalinfo,
            inshort: t("page.privacy.infocollect.personal.inshort"),
            content: [
              t("page.privacy.infocollect.content.1"),
              generateItem({
                strong: t("page.privacy.infocollect.list1.title1"),
                content: t("page.privacy.infocollect.list1.title2"),
              }),
              generateList([
                t("page.privacy.personal.list1.names"),
                t("page.privacy.personal.list1.phones"),
                t("page.privacy.personal.list1.email"),
                t("page.privacy.personal.list1.mailingAddress"),
                t("page.privacy.personal.list1.usernames"),
                t("page.privacy.personal.list1.passwords"),
                t("page.privacy.personal.list1.billing"),
                t("page.privacy.personal.list1.debit"),
                // t("page.privacy.personal.list1.contactPref"),
                // t("page.privacy.personal.list1.contact"),
                // t("page.privacy.personal.list1.phone"),
                t("page.privacy.personal.list1.ring"),
              ]),
              generateItem({
                strong: t("page.privacy.infocollect.list2.titleStrong"),
                strongId: privacyContent.sensitiveinfo,
                content: t("page.privacy.infocollect.list2.title"),
              }),
              generateList([
                t("page.privacy.personal.list2.passport"),
                t("page.privacy.personal.list2.nationalId"),
              ]),
              generateItem({
                strong: t("page.privacy.infocollect.content.2.summary"),
                content: t("page.privacy.infocollect.content.2.content", {
                  link: generateLink({
                    type: "external",
                    link: "https://www.onepay.co.uk/forbusiness",
                    classNames: "underline",
                  }),
                }),
              }),
              generateItem({
                strong: t("page.privacy.infocollect.content.3.summary"),
                content: t("page.privacy.infocollect.content.3.content", {
                  link: generateLink({
                    type: "internal",
                    href: privacyContent.sociallogins,
                    classNames: "underline",
                    alias: t("page.privacy.sociallogins.title"),
                  }),
                }),
              }),
              generateItem({
                strong: t("page.privacy.infocollect.content.4.summary"),
                content: t("page.privacy.infocollect.content.4.content"),
              }),
              generateList(
                [
                  {
                    em: t("page.privacy.infocollect.content.4.list.1.em"),
                    content: t(
                      "page.privacy.infocollect.content.4.list.1.content"
                    ),
                  },
                  {
                    em: t("page.privacy.infocollect.content.4.list.2.em"),
                    content: t(
                      "page.privacy.infocollect.content.4.list.2.content"
                    ),
                  },
                  {
                    em: t("page.privacy.infocollect.content.4.list.3.em"),
                    content: t(
                      "page.privacy.infocollect.content.4.list.3.content"
                    ),
                  },
                ],
                "pt-2"
              ),
              t("page.privacy.infocollect.content.5"),
              t("page.privacy.infocollect.content.6"),
            ],
          },
          {
            title: t("page.privacy.infocollect.collected.title"),
            inshort: t("page.privacy.infocollect.collected.inshort"),
            content: [
              t("page.privacy.infocollect.collected.content.1"),
              t("page.privacy.infocollect.collected.content.2"),
              generateList(
                [
                  {
                    em: t("page.privacy.infocollect.collected.list.1.em"),
                    content: t(
                      "page.privacy.infocollect.collected.list.1.content"
                    ),
                  },
                  {
                    em: t("page.privacy.infocollect.collected.list.2.em"),
                    content: t(
                      "page.privacy.infocollect.collected.list.2.content"
                    ),
                  },
                  {
                    em: t("page.privacy.infocollect.collected.list.3.em"),
                    content: t(
                      "page.privacy.infocollect.collected.list.3.content"
                    ),
                  },
                ],
                "pt-2"
              ),
            ],
          },
          {
            title: t("page.privacy.infocollect.otherSources.title"),
            titleId: privacyContent.othersources,
            inshort: t("page.privacy.infocollect.otherSources.inshort"),
            content: [t("page.privacy.infocollect.otherSources.content")],
          },
        ],
      },
      [privacyContent.infouse]: {
        title: t("page.privacy.infouse.title"),
        href: privacyContent.infouse,
        items: [
          {
            inshort: t("page.privacy.infouse.inshort"),
            content: [
              generateItem({ strong: t("page.privacy.infouse.content.1") }),
              generateList(
                [
                  {
                    strong: t("page.privacy.infouse.list.1.strong"),
                    content: t("page.privacy.infouse.list.1.content"),
                  },
                  {
                    strong: t("page.privacy.infouse.list.2.strong"),
                    content: t("page.privacy.infouse.list.2.content"),
                  },
                  {
                    strong: t("page.privacy.infouse.list.3.strong"),
                    content: t("page.privacy.infouse.list.3.content"),
                  },
                  {
                    strong: t("page.privacy.infouse.list.4.strong"),
                    content: t("page.privacy.infouse.list.4.content"),
                  },
                  {
                    strong: t("page.privacy.infouse.list.5.strong"),
                    content: t("page.privacy.infouse.list.5.content"),
                  },
                  {
                    strong: t("page.privacy.infouse.list.6.strong"),
                    content: t("page.privacy.infouse.list.6.content", {
                      link: generateLink({
                        type: "internal",
                        href: privacyContent.privacyrights,
                        classNames: "underline",
                        alias: t("page.privacy.privacyrights.title"),
                      }),
                    }),
                  },
                  {
                    strong: t("page.privacy.infouse.list.7.strong"),
                    content: t("page.privacy.infouse.list.7.content"),
                  },
                  {
                    strong: t("page.privacy.infouse.list.9.strong"),
                    content: t("page.privacy.infouse.list.9.content"),
                  },
                  {
                    strong: t("page.privacy.infouse.list.10.strong"),
                    content: t("page.privacy.infouse.list.10.content"),
                  },
                  {
                    strong: t("page.privacy.infouse.list.11.strong"),
                    content: t("page.privacy.infouse.list.11.content"),
                  },
                ],
                "pt-2"
              ),
            ],
          },
        ],
      },
      [privacyContent.whoshare]: {
        title: t("page.privacy.whoshare.title"),
        href: privacyContent.whoshare,
        items: [
          {
            inshort: t("page.privacy.whoshare.inshort"),
            content: [
              t("page.privacy.whoshare.content"),
              generateList(
                [
                  {
                    strong: t("page.privacy.whoshare.list.1.strong"),
                    content: t("page.privacy.whoshare.list.1.content"),
                  },
                  {
                    strong: t("page.privacy.whoshare.list.2.strong"),
                    content: t("page.privacy.whoshare.list.2.content"),
                  },
                  {
                    strong: t("page.privacy.whoshare.list.3.strong"),
                    content: t("page.privacy.whoshare.list.3.content"),
                  },
                ],
                "pt-2"
              ),
            ],
          },
        ],
      },
      [privacyContent.sociallogins]: {
        title: t("page.privacy.sociallogins.title"),
        href: privacyContent.sociallogins,
        items: [
          {
            inshort: t("page.privacy.sociallogins.inshort"),
            content: [
              t("page.privacy.sociallogins.content.1"),
              t("page.privacy.sociallogins.content.2"),
            ],
          },
        ],
      },
      [privacyContent.inforetain]: {
        title: t("page.privacy.inforetain.title"),
        href: privacyContent.inforetain,
        items: [
          {
            inshort: t("page.privacy.inforetain.inshort"),
            content: [
              t("page.privacy.inforetain.content.1"),
              t("page.privacy.inforetain.content.2"),
            ],
          },
        ],
      },
      [privacyContent.infosafe]: {
        title: t("page.privacy.infosafe.title"),
        href: privacyContent.infosafe,
        items: [
          {
            inshort: t("page.privacy.infosafe.inshort"),
            content: [t("page.privacy.infosafe.content.1")],
          },
        ],
      },
      [privacyContent.infominors]: {
        title: t("page.privacy.infominors.title"),
        href: privacyContent.infominors,
        items: [
          {
            inshort: t("page.privacy.infominors.inshort"),
            content: [
              t("page.privacy.infominors.content.1", {
                email: getEmailLink("contact"),
              }),
            ],
          },
        ],
      },
      [privacyContent.privacyrights]: {
        title: t("page.privacy.privacyrights.title"),
        href: privacyContent.privacyrights,
        items: [
          {
            inshort: t("page.privacy.privacyrights.inshort"),
            content: [
              t("page.privacy.privacyrights.content.1", {
                link1: generateLink({
                  type: "external",
                  link: "https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm",
                  classNames: "underline",
                  alias: "Member State data protection authority",
                }),
                link2: generateLink({
                  type: "external",
                  link: "https://ico.org.uk/make-a-complaint/data-protection-complaints/data-protection-complaints/",
                  classNames: "underline",
                  alias: "UK data protection authority",
                }),
              }),
              t("page.privacy.privacyrights.content.2", {
                link: generateLink({
                  type: "external",
                  link: "https://www.edoeb.admin.ch/edoeb/en/home.html",
                  classNames: "underline",
                  alias: "Federal Data Protection and Information Commissioner",
                }),
              }),
              generateItem({
                strong: t("page.privacy.privacyrights.content.3.strong"),
                content: t("page.privacy.privacyrights.content.3.content", {
                  link: generateLink({
                    type: "internal",
                    href: privacyContent.contact,
                    classNames: "underline",
                    alias: t("page.privacy.contact.title"),
                  }),
                }),
                strongUnderline: true,
              }),
              t("page.privacy.privacyrights.content.4"),
              generateItem({
                strong: t("page.privacy.privacyrights.content.5.strong"),
                content: t("page.privacy.privacyrights.content.5.content", {
                  link: generateLink({
                    type: "internal",
                    href: privacyContent.contact,
                    classNames: "underline",
                    alias: t("page.privacy.contact.title"),
                  }),
                }),
                strongUnderline: true,
              }),
              generateItem({
                strong: t("page.privacy.infominors.accinfo.title"),
                content: t("page.privacy.privacyrights.accinfo.content.1"),
                strongUnderline: true,
              }),
              generateList([
                t("page.privacy.privacyrights.accinfo.list.1"),
                t("page.privacy.privacyrights.accinfo.list.2"),
              ]),
              t("page.privacy.privacyrights.accinfo.content.2"),
              t("page.privacy.privacyrights.accinfo.content.3", {
                email: getEmailLink("privacy"),
              }),
            ],
          },
        ],
      },
      [privacyContent.dnt]: {
        title: t("page.privacy.dnt.title"),
        href: privacyContent.dnt,
        items: [
          {
            content: [t("page.privacy.dnt.content")],
          },
        ],
      },
      [privacyContent.caresidents]: {
        title: t("page.privacy.caresidents.title"),
        href: privacyContent.caresidents,
        items: [
          {
            inshort: t("page.privacy.caresidents.inshort"),
            content: [
              t("page.privacy.caresidents.content.1"),
              t("page.privacy.caresidents.content.2"),
            ],
          },
          {
            title: t("page.privacy.caresidents.ccpa.title"),
            content: [
              t("page.privacy.caresidents.ccpa.content.1"),
              generateList(
                [
                  t("page.privacy.caresidents.ccpa.list1.1"),
                  t("page.privacy.caresidents.ccpa.list1.2"),
                ],
                undefined,
                "list-inside"
              ),
              t("page.privacy.caresidents.ccpa.content.2"),
              t("page.privacy.caresidents.ccpa.content.3"),
              generateItem({
                strong: t("page.privacy.caresidents.ccpa.content.4"),
              }),
              t("page.privacy.caresidents.ccpa.content.5"),
              generateTable(
                [
                  t("page.privacy.caresidents.ccpa.table.header.category"),
                  t("page.privacy.caresidents.ccpa.table.header.examples"),
                  t("page.privacy.caresidents.ccpa.table.header.collected"),
                ],
                [
                  [
                    t("page.privacy.caresidents.ccpa.table.row1.1"),
                    t("page.privacy.caresidents.ccpa.table.row1.2"),
                    t("page.privacy.caresidents.ccpa.table.row1.3"),
                  ],
                  [
                    t("page.privacy.caresidents.ccpa.table.row2.1"),
                    t("page.privacy.caresidents.ccpa.table.row2.2"),
                    t("page.privacy.caresidents.ccpa.table.row2.3"),
                  ],
                  [
                    t("page.privacy.caresidents.ccpa.table.row3.1"),
                    t("page.privacy.caresidents.ccpa.table.row3.2"),
                    t("page.privacy.caresidents.ccpa.table.row3.3"),
                  ],
                  [
                    t("page.privacy.caresidents.ccpa.table.row4.1"),
                    t("page.privacy.caresidents.ccpa.table.row4.2"),
                    t("page.privacy.caresidents.ccpa.table.row4.3"),
                  ],
                  [
                    t("page.privacy.caresidents.ccpa.table.row5.1"),
                    t("page.privacy.caresidents.ccpa.table.row5.2"),
                    t("page.privacy.caresidents.ccpa.table.row5.3"),
                  ],
                  [
                    t("page.privacy.caresidents.ccpa.table.row6.1"),
                    t("page.privacy.caresidents.ccpa.table.row6.2"),
                    t("page.privacy.caresidents.ccpa.table.row6.3"),
                  ],
                  [
                    t("page.privacy.caresidents.ccpa.table.row7.1"),
                    t("page.privacy.caresidents.ccpa.table.row7.2"),
                    t("page.privacy.caresidents.ccpa.table.row7.3"),
                  ],
                  [
                    t("page.privacy.caresidents.ccpa.table.row8.1"),
                    t("page.privacy.caresidents.ccpa.table.row8.2"),
                    t("page.privacy.caresidents.ccpa.table.row8.3"),
                  ],
                  [
                    t("page.privacy.caresidents.ccpa.table.row9.1"),
                    t("page.privacy.caresidents.ccpa.table.row9.2"),
                    t("page.privacy.caresidents.ccpa.table.row9.3"),
                  ],
                  [
                    t("page.privacy.caresidents.ccpa.table.row10.1"),
                    t("page.privacy.caresidents.ccpa.table.row10.2"),
                    t("page.privacy.caresidents.ccpa.table.row10.3"),
                  ],
                  [
                    t("page.privacy.caresidents.ccpa.table.row11.1"),
                    t("page.privacy.caresidents.ccpa.table.row11.2"),
                    t("page.privacy.caresidents.ccpa.table.row11.3"),
                  ],
                  [
                    t("page.privacy.caresidents.ccpa.table.row12.1"),
                    t("page.privacy.caresidents.ccpa.table.row12.2"),
                    t("page.privacy.caresidents.ccpa.table.row12.3"),
                  ],
                ]
              ),
              t("page.privacy.caresidents.ccpa.content.6"),
              generateList([
                t("page.privacy.caresidents.ccpa.list2.1"),
                t("page.privacy.caresidents.ccpa.list2.2"),
                t("page.privacy.caresidents.ccpa.list2.3"),
                t("page.privacy.caresidents.ccpa.list2.4"),
                t("page.privacy.caresidents.ccpa.list2.5"),
                t("page.privacy.caresidents.ccpa.list2.6"),
              ]),
              t("page.privacy.caresidents.ccpa.content.7"),
              t("page.privacy.caresidents.ccpa.content.8"),
              generateList([
                t("page.privacy.caresidents.ccpa.list3.1"),
                t("page.privacy.caresidents.ccpa.list3.2"),
                t("page.privacy.caresidents.ccpa.list3.3"),
              ]),
              generateItem({
                strong: t("page.privacy.caresidents.ccpa.content.9"),
              }),
              t("page.privacy.caresidents.ccpa.content.10"),
              t("page.privacy.caresidents.ccpa.content.11", {
                email: getEmailLink("contact"),
                link: generateLink({
                  type: "internal",
                  path: "contact-us",
                  classNames: "underline",
                }),
              }),
              t("page.privacy.caresidents.ccpa.content.12"),
              generateItem({
                strong: t("page.privacy.caresidents.ccpa.content.13"),
              }),
              t("page.privacy.caresidents.ccpa.content.14"),
              t("page.privacy.caresidents.ccpa.content.15"),
              t("page.privacy.caresidents.ccpa.content.16", {
                companyName: t("contact.companyName"),
              }),
              generateItem({
                strong: t("page.privacy.caresidents.ccpa.content.17"),
              }),
              generateItem({
                content: t("page.privacy.caresidents.ccpa.content.34"),
                contentUnderline: true,
              }),
              t("page.privacy.caresidents.ccpa.content.35"),
              generateItem({
                content: t("page.privacy.caresidents.ccpa.content.18"),
                contentUnderline: true,
              }),
              t("page.privacy.caresidents.ccpa.content.19"),
              generateList([
                t("page.privacy.caresidents.ccpa.list4.1"),
                t("page.privacy.caresidents.ccpa.list4.2"),
                t("page.privacy.caresidents.ccpa.list4.3"),
                t("page.privacy.caresidents.ccpa.list4.4"),
                t("page.privacy.caresidents.ccpa.list4.5"),
                t("page.privacy.caresidents.ccpa.list4.6"),
                t("page.privacy.caresidents.ccpa.list4.7"),
                t("page.privacy.caresidents.ccpa.list4.8"),
              ]),
              t("page.privacy.caresidents.ccpa.content.20"),
              generateItem({
                content: t("page.privacy.caresidents.ccpa.content.21"),
                contentUnderline: true,
              }),
              t("page.privacy.caresidents.ccpa.content.22"),
              generateItem({
                content: t("page.privacy.caresidents.ccpa.content.23"),
                contentUnderline: true,
              }),
              t("page.privacy.caresidents.ccpa.content.24"),
              generateList([
                t("page.privacy.caresidents.ccpa.list5.1"),
                t("page.privacy.caresidents.ccpa.list5.2"),
                t("page.privacy.caresidents.ccpa.list5.3"),
                t("page.privacy.caresidents.ccpa.list5.4"),
                t("page.privacy.caresidents.ccpa.list5.5"),
                t("page.privacy.caresidents.ccpa.list5.6"),
                t("page.privacy.caresidents.ccpa.list5.7"),
                t("page.privacy.caresidents.ccpa.list5.8"),
              ]),
              t("page.privacy.caresidents.ccpa.content.25"),
              t("page.privacy.caresidents.ccpa.content.26"),
              t("page.privacy.caresidents.ccpa.content.27"),
              t("page.privacy.caresidents.ccpa.content.28", {
                email: getEmailLink("privacy"),
                link: generateLink({
                  type: "internal",
                  path: "data-request",
                  classNames: "underline",
                }),
              }),
              generateItem({
                content: t("page.privacy.caresidents.ccpa.content.29"),
                contentUnderline: true,
              }),
              t("page.privacy.caresidents.ccpa.content.30"),
              t("page.privacy.caresidents.ccpa.content.31"),
              generateItem({
                content: t("page.privacy.caresidents.ccpa.content.32"),
                contentUnderline: true,
              }),
              generateList([
                t("page.privacy.caresidents.ccpa.list6.1"),
                t("page.privacy.caresidents.ccpa.list6.2"),
                t("page.privacy.caresidents.ccpa.list6.3"),
                t("page.privacy.caresidents.ccpa.list6.4"),
              ]),
              t("page.privacy.caresidents.ccpa.content.33", {
                email: getEmailLink("contact"),
                link: generateLink({
                  type: "internal",
                  path: "contact-us",
                  classNames: "underline",
                }),
              }),
            ],
          },
        ],
      },
      [privacyContent.policyupdate]: {
        title: t("page.privacy.policyupdate.title"),
        href: privacyContent.policyupdate,
        items: [
          {
            inshort: t("page.privacy.policyupdate.inshort"),
            content: [t("page.privacy.policyupdate.content")],
          },
        ],
      },
      [privacyContent.contact]: {
        title: t("page.privacy.contact.title"),
        href: privacyContent.contact,
        items: [
          {
            content: [
              t("page.privacy.contact.content", {
                email: getEmailLink("contact"),
              }),
              generateList(
                [
                  t("contact.companyName"),
                  t("contact.companyAddressContent"),
                  t("contact.zipCode"),
                ],
                undefined,
                "list-inside"
              ),
            ],
          },
        ],
      },
      [privacyContent.request]: {
        title: t("page.privacy.request.title"),
        href: privacyContent.request,
        items: [
          {
            content: [
              t("page.privacy.request.conntent", {
                link: generateLink({
                  type: "internal",
                  path: "data-request",
                  classNames: "underline",
                }),
              }),
            ],
          },
        ],
      },
    };
    return { content };
  }, [t]);

  return <PolicyContent data={tableContent} />;
}
