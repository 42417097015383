import RingPng from "@app-assets/images/mt-ring.png";
import PouchPng from "@app-assets/images/pouch.png";
import ManualPng from "@app-assets/images/user-manual.png";

export const mockDetail = {
  description: "page.product.description",
  specifications: [
    {
      id: "1",
      title: "page.product.specifications.title",
      subtitle: "page.product.specifications.subtitle",
      content: "page.product.specifications.content",
    },
    {
      id: "2",
      title: "page.product.specifications.title1",
      subtitle: "page.product.specifications.subtitle1",
      content: "page.product.specifications.content1",
    },
    {
      id: "3",
      title: "page.product.specifications.title2",
      subtitle: "page.product.specifications.subtitle2",
      content: "page.product.specifications.content2",
    },
  ],
  boxComponents: [
    {
      image: RingPng,
      placeholder: "page.product.theRing",
    },
    {
      image: ManualPng,
      placeholder: "page.product.userManual",
    },
    {
      image: PouchPng,
      placeholder: "page.product.pouch",
    },
  ],
  summary: {
    title: "",
    content: "page.product.summary",
  },
};
