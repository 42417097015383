import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import PolicyContent, { PolicyContentData } from "./PolicyContent";

export default function ShippingContent() {
  const { t } = useTranslation();

  const tableContent: PolicyContentData = useMemo(() => {
    return {
      hideTableContent: true,
      content: {
        returnsExchanges: {
          items: [
            {
              title: t("page.shipping.method.title"),
              content: [t("page.shipping.method.content")],
            },
            {
              title: t("page.shipping.time.title"),
              content: [t("page.shipping.time.content")],
            },
            {
              title: t("page.shipping.disclaimer.title"),
              content: [t("page.shipping.disclaimer.content")],
            },
            {
              title: t("page.shipping.goodsInspection.title"),
              content: [
                t("page.shipping.goodsInspection.content1"),
                t("page.shipping.goodsInspection.content2"),
                t("page.shipping.goodsInspection.content3"),
                t("page.shipping.goodsInspection.content4"),
                t("page.shipping.goodsInspection.content5"),
              ],
            },
          ],
        },
      },
    };
  }, [t]);

  return <PolicyContent data={tableContent} />;
}
