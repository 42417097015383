import { useTranslation } from "react-i18next";

import MTBreadcrumb from "@app-components/header/MTBreadcrumb";
import PolicyContact from "@app-components/policy/PolicyContact";
import { getEmailLink } from "@app-components/policy/PolicyContent";
import TermsContent from "@app-components/policy/TermsContent";
import {
  DISPLAY_PHONE_NUMBER,
  PHONE_NUMBER,
} from "@app-constants/common";
import { getRoutes } from "@app-utils/routes";

export default function Terms() {
  const { t } = useTranslation();
  const routes = getRoutes(t);

  const phoneNumber = `<a href="tel:${PHONE_NUMBER}">${DISPLAY_PHONE_NUMBER}</a>`;

  const link = `<a href="https://manytouches.com">https://manytouches.com</a>`;

  return (
    <div className="container policy faq-detail">
      <MTBreadcrumb items={[routes.home, routes.terms]} />
      <div className="faq-detail-container">
        <div className="wrapper">
          <div className="faq-detail-header">
            <div className="faq-detail-header-container">
              <div className="faq-detail-title">{t("page.terms.title")}</div>
              <div className="faq-detail-subtitle">
                {t("page.terms.subtitle")}
              </div>
            </div>
          </div>
          <div className="policy-content">
            <div className="mt-title-xl pb-4">
              {t("page.terms.agreement.title")}
            </div>
            <div className="mt-text-body-lg">
              <p>
                {t("page.terms.agreement.content1", {
                  companyName: t("contact.companyName"),
                  address: t("contact.companyAddressContent"),
                })}
              </p>
              <p
                className="pt-4"
                dangerouslySetInnerHTML={{
                  __html: t("page.terms.agreement.content2", { link }),
                }}
              ></p>
              <p
                className="pt-4"
                dangerouslySetInnerHTML={{
                  __html: t("page.terms.agreement.content3", {
                    phone: phoneNumber,
                    email: getEmailLink("destouches"),
                    address: t("contact.companyAddressContent"),
                  }),
                }}
              ></p>
              <p className="pt-4">
                {t("page.terms.agreement4", {
                  companyName: t("contact.companyName"),
                })}
              </p>
              <p
                className="pt-4"
                dangerouslySetInnerHTML={{
                  __html: t("page.terms.agreement5", {
                    email: getEmailLink("info"),
                  }),
                }}
              ></p>
              <p className="pt-4">{t("page.terms.agreemen6")}</p>
              <p className="pt-4">{t("page.terms.agreement7")}</p>
            </div>
            <TermsContent />
          </div>
          <PolicyContact />
        </div>
      </div>
    </div>
  );
}
