import { useTranslation } from "react-i18next";

import { AgencyForm } from "@app-components/agency/AgencyForm";
import Header from "@app-components/header/Header";
import RingOverview from "@app-components/ringFunction/RingOverview";
import useScrollToTop from "@app-hook/useScrollToTop";

export default function AgencyLandingPage() {
  useScrollToTop();

  const { t } = useTranslation();

  return (
    <div className="agency-page">
      <Header />
      <div className="container agency-page__content">
        <div className="agency-page__overview">
          <RingOverview />
          <h1 className="mt-heading-xxl text-center pt-[40px] title">
            {t("title.agency")}
          </h1>
        </div>
        <div className="agency-page__form">
          <AgencyForm />
        </div>
      </div>
    </div>
  );
}
