import { FaqItemProps } from "@app-components/faq/FaqSection";

export const PaymentMethod: { [key: string]: string } = {
  COD: "COD",
  // ONEPAY: "GATEWAY",
};

export const OrderStatus = {
  PENDING: "PENDING",
  PAID: "PAID",
  FAILED: "FAILED",
};

export const preOrderPolicy: FaqItemProps = {
  label: "page.faq.section1.label",
  items: [
    {
      label: "page.preOrder.q1.label",
      content: "page.preOrder.q1.content",
    },
    {
      label: "page.preOrder.q2.label",
      content: "page.preOrder.q2.content",
    },
    {
      label: "page.preOrder.q3.label",
      content: "page.preOrder.q3.content",
    },
    {
      label: "page.preOrder.q4.label",
      content: "page.preOrder.q4.content",
    },
    {
      label: "page.preOrder.q5.label",
      subContent: [
        {
          items: [
            "page.preOrder.q5.subContent1",
            "page.preOrder.q5.subContent2",
            "page.preOrder.q5.subContent3",
            "page.preOrder.q5.subContent4",
          ],
        },
      ],
      summary: "page.preOrder.q5.summary",
    },
    {
      label: "page.preOrder.q6.label",
      content: "page.preOrder.q6.content",
    },
    {
      label: "page.preOrder.q7.label",
      content: "page.preOrder.q7.content",
    },
    {
      label: "page.preOrder.q8.label",
      content: "page.preOrder.q8.content",
    },
    {
      label: "page.preOrder.q9.label",
      content: "page.preOrder.q9.content",
    },
    {
      label: "page.preOrder.q10.label",
      content: "page.preOrder.q10.content",
    },
    {
      label: "page.preOrder.q11.label",
      content: "page.preOrder.q11.content",
    },
    {
      label: "page.preOrder.q12.label",
      content: "page.preOrder.q12.content",
    },
  ],
};
