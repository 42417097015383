import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import PolicyContent, {
  PolicyContentData,
  generateList,
} from "./PolicyContent";

export default function WarrantyContent() {
  const { t } = useTranslation();

  const tableContent: PolicyContentData = useMemo(() => {
    return {
      hideTableContent: true,
      content: {
        covered: {
          title: t("page.warranty.covered.title"),
          items: [
            {
              content: [
                generateList([
                  t("page.warranty.covered.content.1"),
                  t("page.warranty.covered.content.2"),
                ]),
              ],
            },
          ],
        },
        notCovered: {
          title: t("page.warranty.notCovered.title"),
          items: [
            {
              content: [
                generateList([
                  t("page.warranty.notCovered.content.1"),
                  t("page.warranty.notCovered.content.2"),
                  t("page.warranty.notCovered.content.3"),
                  t("page.warranty.notCovered.content.4"),
                ]),
              ],
            },
          ],
        },
      },
    };
  }, [t]);

  return <PolicyContent data={tableContent} />;
}
