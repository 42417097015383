import { useTranslation } from "react-i18next";

import MTBreadcrumb from "@app-components/header/MTBreadcrumb";
import WarrantyContent from "@app-components/policy/WarrantyContent";
import { getRoutes } from "@app-utils/routes";

export default function WarrantyPolicyPage() {
  const { t } = useTranslation();
  const routes = getRoutes(t);

  return (
    <div className="container policy faq-detail">
      <MTBreadcrumb items={[routes.home, routes.warrantyPolicy]} />
      <div className="faq-detail-container">
        <div className="wrapper">
          <div className="faq-detail-header">
            <div className="faq-detail-header-container">
              <div className="faq-detail-title">{t("page.warranty.title")}</div>
            </div>
          </div>
          <WarrantyContent />
        </div>
      </div>
    </div>
  );
}
