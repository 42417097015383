import { Modal } from "antd";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { createSearchParams } from "react-router-dom";
import validator from "validator";

import StyledButton from "@app-components/button/StyledButton";
import MTInput from "@app-components/input/MTInput";
import { SolidIcon } from "@app-components/svg/icon";
import { AGENCY_GOOGLE_FORM } from "@app-constants/common";
import agencyServices from "@app-services/agencyServices";
import useService from "@app-services/shared/use_service";

const useRegisterAgency = () => useService(agencyServices.registerAgency);

const defaultAgency = {
  phone: "",
  email: "",
};

export function AgencyForm() {
  const { t } = useTranslation();
  const [registerAgencyPayload, onRegisterAgency, clearRegisterAgencyState] =
    useRegisterAgency();

  const [agency, setAgency] = useState(defaultAgency);
  const [showPhoneError, setShowPhoneError] = useState(false);
  const [showEmailError, setShowEmailError] = useState(false);

  const validEmail = validator.isEmail(agency.email);
  const validPhone = validator.isMobilePhone(agency.phone, "vi-VN");
  const [showModal, setShowModal] = useState(false);

  const disabledButton = useMemo(() => {
    return !Object.values(agency).every((value) => !!value);
  }, [agency]);

  useEffect(() => {
    if (registerAgencyPayload.error || registerAgencyPayload.success) {
      setShowModal(true);
    }
  }, [registerAgencyPayload.error, registerAgencyPayload.success]);

  const handleOnSubmit = () => {
    const searchParams = createSearchParams({
      [AGENCY_GOOGLE_FORM.form.phone.id]: agency.phone,
      [AGENCY_GOOGLE_FORM.form.email.id]: agency.email,
    });
    onRegisterAgency(searchParams.toString());
  };

  const handleOnCloseModal = () => {
    clearRegisterAgencyState();
    setShowModal(false);
    setShowEmailError(false);
    setShowPhoneError(false);
    setAgency(defaultAgency);
  };

  return (
    <div className="mt-form">
      <h2 className="mt-heading-xl mb-[8px]">
        {t("page.pns.hero.form.title")}
      </h2>

      <h3 className="subtitle">{t("page.pns.hero.form.subtitle")}</h3>

      <div className="flex flex-col gap-[16px]">
        <MTInput
          label={t("input.phoneNumber.label")}
          value={agency.phone}
          disabled={registerAgencyPayload.loading}
          onChangeText={(phone) => setAgency((prev) => ({ ...prev, phone }))}
          error={showPhoneError && !validPhone ? t("content.invalidPhone") : ""}
          onFocus={() => !agency.phone && setShowPhoneError(false)}
          onBlur={() => setShowPhoneError(!!agency.phone)}
        />
        <MTInput
          label={t("input.email.label")}
          value={agency.email}
          disabled={registerAgencyPayload.loading}
          onChangeText={(email) => setAgency((prev) => ({ ...prev, email }))}
          error={showEmailError && !validEmail ? t("content.invalidEmail") : ""}
          onFocus={() => !agency.email && setShowEmailError(false)}
          onBlur={() => setShowEmailError(!!agency.email)}
        />
        <StyledButton
          disabled={disabledButton}
          onClick={handleOnSubmit}
          className="primary mt-button-lg w-full"
        >
          {t("button.submit")}
        </StyledButton>
      </div>

      <Modal
        open={showModal}
        onCancel={handleOnCloseModal}
        title={null}
        footer={null}
      >
        {registerAgencyPayload.error && (
          <div className="flex flex-col justify-center items-center gap-2">
            <SolidIcon.Warning color="red" className="h-[82px] w-[82px]" />

            <div className="mt-title-xxl text-center">
              {registerAgencyPayload.error}
            </div>
            <div className="mt-title-xxl text-center">{t("errorCode.429")}</div>
          </div>
        )}
        {registerAgencyPayload.success && (
          <div className="flex flex-col justify-center items-center gap-2">
            <div>
              <SolidIcon.Check className="h-[82px] w-[82px]" />
            </div>

            <div className="mt-title-xxl text-center whitespace-break-spaces">
              {t("page.pns.hero.form.submitSuccess")}
            </div>
          </div>
        )}
      </Modal>
    </div>
  );
}
