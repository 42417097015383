import { Divider } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import MTInputOuline from "@app-components/input/MTInputOutline";
import MTSelect from "@app-components/select/MTSelect";
import MeasureRingStep1 from "@app-components/svg/MeasureRingStep1";
import MeasureRingStep2 from "@app-components/svg/MeasureRingStep2";
import MeasureRingStep3 from "@app-components/svg/MeasureRingStep3";
import { OutlineIcon } from "@app-components/svg/icon";
import { circumferenceOptions } from "@app-constants/product";

export default function MeasureRingGuide({
  onSelectRingSize,
}: {
  onSelectRingSize?: (val: number) => void;
}) {
  const { t } = useTranslation();
  const [showMeasureGuide, setShowMeasureGuide] = useState<boolean>(false);
  const [resultRingSize, setResultRingSize] = useState<number>();

  const handleOnResultChange = (value: number) => {
    setResultRingSize(value);
    onSelectRingSize?.(value);
  };

  return (
    <div className="measure-ring-guide">
      <div
        onClick={() => setShowMeasureGuide(true)}
        className="mt-text-body-xl underline cursor-pointer"
      >
        {t("page.product.measureYourRing.title")}
      </div>
      {showMeasureGuide && (
        <>
          <div
            className="overlay"
            onClick={() => setShowMeasureGuide(false)}
          ></div>
          <div className="modal">
            <div className="guide-wrapper mt-text-body-md">
              <div className="result-title">
                {t("page.product.measureYourRing.title")}
              </div>
              <div className="guide-row">
                <div className="guide-col">
                  <div className="guide-card desc">
                    <div>
                      <OutlineIcon.NumberOneCircle />
                    </div>
                    <div>{t("page.product.measureYourRing.step1")}</div>
                  </div>
                  <div className="guide-card img">
                    <MeasureRingStep1 />
                  </div>
                </div>
                <div className="guide-col">
                  <div className="guide-card desc">
                    <div>
                      <OutlineIcon.NumberTwoCircle />
                    </div>
                    <div>{t("page.product.measureYourRing.step2")}</div>
                  </div>
                  <div className="guide-card img">
                    <MeasureRingStep2 />
                  </div>
                </div>
                <div className="guide-col">
                  <div className="guide-card desc">
                    <div>
                      <OutlineIcon.NumberThreeCircle />
                    </div>
                    <div>{t("page.product.measureYourRing.step3")}</div>
                  </div>
                  <div className="guide-card img">
                    <MeasureRingStep3 />
                  </div>
                </div>
              </div>
              <Divider />
              <div>
                <div className="result-title">
                  {t("page.product.measureYourRing.theResult")}
                </div>
                <div className="input-wrapper-row">
                  <div className="flex flex-col gap-[16px] flex-1">
                    <div className="mt-title-xl text-center">
                      {t("page.product.measureYourRing.yourResult")}
                    </div>
                    <div>
                      <MTSelect
                        placeholder={t(
                          "page.product.measureYourRing.selectSize"
                        )}
                        options={circumferenceOptions}
                        value={resultRingSize}
                        onChange={handleOnResultChange}
                      />
                    </div>
                  </div>
                  <div className="flex flex-col gap-[16px] flex-1">
                    <div className="mt-title-xl text-center">
                      {t("page.product.measureYourRing.yourRingSize")}
                    </div>
                    <div>
                      <MTInputOuline
                        value={resultRingSize?.toString()}
                        placeholder={t(
                          "page.product.measureYourRing.yourRingSize"
                        )}
                        readonly
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}
