import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import PolicyContent, {
  PolicyContentData,
  generateList,
} from "./PolicyContent";

export default function ReturnsContent() {
  const { t } = useTranslation();

  const tableContent: PolicyContentData = useMemo(() => {
    return {
      hideTableContent: true,
      hideIndex: true,
      titleStyles: "mt-title-xxl",
      content: {
        returnsExchanges: {
          title: t("page.returns.returnsExchanges.title"),
          items: [
            {
              title: t("page.returns.returnsExchanges.period.title"),
              content: [t("page.returns.returnsExchanges.content")],
            },
            {
              title: t("page.returns.returnsExchanges.eligible.title"),
              content: [
                t("page.returns.returnsExchanges.eligible.content.1"),
                generateList([
                  t("page.returns.returnsExchanges.eligible.content.2"),
                  t("page.returns.returnsExchanges.eligible.content.3"),
                  t("page.returns.returnsExchanges.eligible.content.4"),
                  t("page.returns.returnsExchanges.eligible.content.5"),
                  t("page.returns.returnsExchanges.eligible.content.6"),
                ]),
              ],
            },
            {
              title: t("page.returns.returnsExchanges.conditions.title"),
              content: [
                generateList([
                  t("page.returns.returnsExchanges.conditions.content.1"),
                  t("page.returns.returnsExchanges.conditions.content.2"),
                ]),
              ],
            },
            {
              title: t("page.returns.returnsExchanges.costs.title"),
              content: [
                generateList([
                  t("page.returns.returnsExchanges.costs.content.1"),
                  t("page.returns.returnsExchanges.costs.content.2"),
                ]),
              ],
            },
          ],
        },
        refund: {
          title: t("page.returns.refund.title"),
          items: [
            {
              content: [
                generateList([
                  t("page.returns.refund.content.1"),
                  t("page.returns.refund.content.2"),
                ]),
              ],
            },
          ],
        },
      },
    };
  }, [t]);

  return <PolicyContent data={tableContent} />;
}
