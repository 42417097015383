import { atom, useSetAtom } from "jotai";
import cloneDeep from "lodash/cloneDeep";

import { CartData } from "@app-interface/cart";
import { migrateCartData } from "@app-jotai/utils";
import { generateCartId } from "@app-utils/cart";
import { toNumber } from "@app-utils/number";

import { getProductApiAtom, getProductsApiAtom } from "./api_atoms";
import { cartListAtom } from "./atoms";

const clearPurchasedCartAtom = atom(null, (get, set) => {
  const cartList = get(cartListAtom);
  set(
    cartListAtom,
    cartList.filter((i) => !i.selected)
  );
});

const updateCartListAtom = atom(
  null,
  (get, set, item: CartData, currentId?: string) => {
    let currentIndex = -1;
    const cartList = get(cartListAtom);
    const newId = generateCartId(item.product.id, item.size, item.color);
    const newCartList: CartData[] = migrateCartData(cloneDeep(cartList));
    const sameItem = newCartList.find((itm, idx) => {
      currentIndex = idx;
      return itm.cartId === newId;
    });
    if (((currentId && currentId !== newId) || !currentId) && sameItem) {
      const updated = {
        ...item,
        quantity: toNumber(item.quantity) + toNumber(sameItem.quantity),
      };
      if (currentId) {
        const removeIndex = newCartList.findIndex(
          (itm) => itm.cartId === currentId
        );
        newCartList[currentIndex] = updated;
        newCartList.splice(removeIndex, 1);
      } else {
        newCartList[currentIndex] = updated;
      }
    } else if (!!currentId) {
      newCartList[currentIndex] = item;
    } else {
      newCartList.push(item);
    }
    set(cartListAtom, newCartList);
  }
);
const deleteCartItem = atom(null, (get, set, currentId?: string) => {
  const cartList = get(cartListAtom);
  const newCartList = cloneDeep(cartList);
  const idIndex = newCartList.findIndex((item) => item.cartId === currentId);
  if (idIndex !== -1) {
    newCartList.splice(idIndex, 1);
    set(cartListAtom, newCartList);
  }
});

const useProductWriteOnly = () => {
  const onGetProducts = useSetAtom(getProductsApiAtom);

  const onGetProduct = useSetAtom(getProductApiAtom);

  const onClearPurchasedCart = useSetAtom(clearPurchasedCartAtom);

  const onUpdateCartList = useSetAtom(updateCartListAtom);

  const onDeleteCartItem = useSetAtom(deleteCartItem);

  return {
    onGetProduct,
    onGetProducts,
    onClearPurchasedCart,
    onUpdateCartList,
    onDeleteCartItem,
  };
};

export { useProductWriteOnly };
