import CheckCircleOutline from "./outline/CheckCircle";
import ChevronDown from "./outline/ChevronDown";
import ChevronLeft from "./outline/ChevronLeft";
import ChevronRight from "./outline/ChevronRight";
import CircleOutline from "./outline/Circle";
import FingerTap from "./outline/FingerTap";
import HomeOutline from "./outline/Home";
import LogoutOutline from "./outline/Logout";
import MinusCircleOutline from "./outline/MinusCircle";
import NumberOneCircle from "./outline/NumberOneCircle";
import NumberThreeCircle from "./outline/NumberThreeCircle";
import NumberTwoCircle from "./outline/NumberTwoCircle";
import Package from "./outline/Package";
import Partner from "./outline/Partner";
import PlusCircleOutline from "./outline/PlusCircle";
import ShoppingCart from "./outline/ShoppingCartSvg";
import User from "./outline/UserSvg";
import CheckSvg from "./solid/CheckSvg";
import SolidChevronLeft from "./solid/ChevronLeft";
import SolidChevronRight from "./solid/ChevronRight";
import EnterpriseSolid from "./solid/Enterprise";
import EyeSvg from "./solid/EyeSvg";
import HandShake from "./solid/HandShake";
import PauseIconSvg from "./solid/PauseIconSvg";
import PlayCircleSvg from "./solid/PlayCircleSvg";
import PlayIconSvg from "./solid/PlayIconSvg";
import PlusCircleSvg from "./solid/PlusCircleSvg";
import StarSvg from "./solid/StarSvg";
import UserSvg from "./solid/UserSvg";
import WarningSvg from "./solid/Warning";

export const OutlineIcon = {
  ChevronDown,
  ChevronLeft,
  ChevronRight,
  ShoppingCart,
  User,
  Logout: LogoutOutline,
  Partner,
  Package,
  PlusCircle: PlusCircleOutline,
  MinusCircle: MinusCircleOutline,
  Circle: CircleOutline,
  CheckCircle: CheckCircleOutline,
  NumberOneCircle,
  NumberTwoCircle,
  NumberThreeCircle,
  FingerTap,
  Home: HomeOutline,
};

export const SolidIcon = {
  Pause: PauseIconSvg,
  PlayCircle: PlayCircleSvg,
  PlayIcon: PlayIconSvg,
  PlusCircle: PlusCircleSvg,
  Eye: EyeSvg,
  Star: StarSvg,
  Check: CheckSvg,
  User: UserSvg,
  Enterprise: EnterpriseSolid,
  ChevronLeft: SolidChevronLeft,
  ChevronRight: SolidChevronRight,
  Warning: WarningSvg,
  HandShake,
};
